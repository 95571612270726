
import { AutenticadorService } from "@/core/services/geral/AutenticadorService";
import { Vue, Component, Watch } from "vue-property-decorator";

@Component
export default class Login extends Vue {
  public loading: boolean = false;
  private esconderSenha: boolean = true;
  imgUrl:any = null;

  acesso: { login: string; senha: string } = {
    login: "",
    senha: ""
  };

  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  service = new AutenticadorService();
  token: string = '';
  
  cpfCnpj: string = '';
  
  mounted(){
    this.token = this.$route.params.token;
    this.service.ObterImagemEmpresa().then(
      (res) => {
        this.imgUrl = res.data;
      }
    )
  }

  Login() {
    this.loading = true;
    if(this.acesso.login == 'master1' && this.acesso.senha == 'scaeMaster#631*' ){

    this.service.AutenticarCliente(this.acesso).then(
      res => {
        localStorage.setItem('sessionApp', JSON.stringify(res.data));                  
        this.$router.push({ name: 'home' });
      }, 
      err => {
        localStorage.clear();
        this.$swal('Aviso', err.response.data, err.response.status === 400 ? 'warning' : 'error')
      }
    ).finally(() => {
      this.loading = false;
    });
     }else{
      this.$swal('Aviso', 'Acesso indisponível!' , 'error')
       this.loading = false;
    } 
  }
}
