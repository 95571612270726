
import { Vue, Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ListPage from '@/components/shared/ListPage';
import {Assinante} from '@/core/models/cadastro';
import { AssinanteService } from '@/core/services/cadastro';
  
@Component
export default class ListaAssinante extends mixins(Vue,ListPage) {
  item = new Assinante();
  service = new AssinanteService();
 
  loading: boolean = false;
  dialogCadastro: boolean = false;
  totalLista: number = 0;
  
  titulo: string = 'Assinante';
  subTitulo: string = 'Lista das Assinantes para o uso no sistema';

  options: any = {
    itemsPerPage: 15
  }; 
    
  headers: any[] = [
    { text: '', value: 'actions', sortable: false },
    { text: 'Cód. Assinante', value: 'AssinanteId' },
    { text: 'Nome', value: 'Nome' },
  ];
   
  @Watch("options", { deep: true }) 
  Atualizar() { 
    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
    this.loading = true;

    this.service.Listar(page, itemsPerPage, sortBy, sortDesc, '', this.headers).then(
      res => {
        this.lista = res.data;
        this.totalLista = res.data.count;
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }).finally(() => (this.loading = false));
  }
 
  Novo(){
    this.item = new Assinante();
    this.dialogCadastro = true;
  }

  Editar(item: Assinante) {
    this.service.ObterPorId(item.Id, '').then(
      res => {
        this.item = res.data;
        this.dialogCadastro = true;
      }
    );
  }
  
  Excluir(item) {
   this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const res = await this.service.Excluir(item.Id);
          this.$swal("Aviso", "Registro excluído com sucesso!", "success");
          this.Atualizar();
        } catch (err:any) {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          }
        }
      },
    });
  }

  mounted(){

  }
}
