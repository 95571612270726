
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import * as jsonpatch from 'fast-json-patch';
import Assinante from "@/core/models/cadastro/Assinante";
import { AssinanteDominio, Dominio } from "@/core/models/cadastro";
import { EnderecoService } from "@/core/services/geral";
import { AssinanteDominioService } from "@/core/services/cadastro";
 
@Component
export default class CadastroAssinanteDominio extends Vue {
  @Prop() public item!: AssinanteDominio;
  @Prop() public value!: string;

  service = new AssinanteDominioService();
  serviceEndereco = new EnderecoService();

  dominioPrincipal = new Dominio();

  listaMunicipio:any = [];
  listaEstado:any = [];

  tab = 0;
  dominioIndex: number = -1;

  valid:boolean = true;
  dialog:boolean = false;
  validContato:boolean = true;
  carregando:boolean = false;
  overlay:boolean = false;

  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  observer! : jsonpatch.Observer<Assinante>; 
   
  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false; 
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  headersDominio: any[] = [
    { text: "", value: "actions", sortable: false },
    { text: "Nome", value: "nome" },
    { text: "Principal", value: "principal" },
  ];

  $refs!: {
    form: HTMLFormElement;
    formDominio:HTMLFormElement
  };

  
  @Watch("item.responsavel.endereco.estadoId")
  @Watch("item.endereco.estadoId")
  CarregarMunicipio(id: any) {
    this.serviceEndereco.ListarMunicipiosPorIdEstadual(id).then(res => {
      this.listaMunicipio = res.data.items;
      this.listaMunicipio
    }, 
    (err) => {
      if (!err.response) {
        this.$swal("Aviso", "Não foi possível acessar a API", "error");
      } else if (err.response.status == 403) {
        this.$swal("Aviso", "", "warning" );
      }
    })
  }

  @Watch("item")
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }
  
  Salvar() {
    if (this.$refs.form.validate()) {   
      this.overlay = !this.overlay; 

      this.service.Salvar(this.item, this.item.id).then(
        (res) => {
          this.$swal("Aviso","Operação realizada com sucesso!", "success");
          this.$emit("salvou");
          this.Close();
        },
        (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", "", "warning" );
          }
       }).finally(() => this.overlay = false);
    }
  }

  Close() {
    this.dialog = false;
  };

  AdicionarDominio(){
     if (this.$refs.formDominio.validate()) {
      if (this.dominioIndex == -1) {
        this.item.dominios.push(this.dominioPrincipal);

      }
      this.dominioPrincipal = new Dominio();
      this.dominioIndex = -1;
    }
  }

  EditarDominio(item: Dominio) {
    if (this.dominioIndex != -1) {
      this.$swal("Cuidado!", "Não é possível editar um registro sem finalizar uma edição já em andamento.","error");
      return;
    };
    this.dominioPrincipal = item;
    this.dominioIndex = this.item.dominios.indexOf(item);
  }

  ExcluirDominio(item) {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      //type: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
    }).then((result) => {
      if (result.value) {
        const index = this.item.dominios.indexOf(item);
        this.item.dominios.splice(index, 1);
        this.$swal("Aviso", "Contato excluído com sucesso!", "success");
      }
    });
  }

  BuscarCep(tipo:string, value:any, path:string){
    if(tipo.toLocaleLowerCase() === "cep" && value.length == 9 && !this.carregando){
      this.carregando = true; 
      this.serviceEndereco.EncontrarEnderecoPorCep(value).then((res) => {
        // Usando a função set para definir dinamicamente o caminho do objeto
        this.set(this.item, path + '.cep', res.data.cep);
        this.set(this.item, path + '.bairro', res.data.bairro);
        this.set(this.item, path + '.logradouro', res.data.logradouro);
        this.set(this.item, path + '.municipioId', res.data.municipioId);
        this.set(this.item, path + '.estadoId', res.data.estadoId);
        this.carregando = false; 
      }, 
      (err) => {
        this.$swal("Erro!", `Erro ao efetuar a consulta do cep ${value}. Verifique se o CEP informado é válido e tente novamente.`, "error");          
        this.carregando = false; 
      });
    }
  }

  // Função auxiliar para definir valores em um objeto com base em um caminho
  set(object, path, value) {
    const keys = path.split('.');
    let current = object;

    keys.forEach((key, index) => {
      if (index === keys.length - 1) {
        current[key] = value;
      } else {
        current = current[key];
      }
    });
  }

  mounted(){
    this.serviceEndereco.ListarEstados().then(res => {
      this.listaEstado = res.data.items;
      this.listaEstado.sort((x,y) => (x.nome > y.nome) ? 1 : ((y.nome > x.nome) ? -1 : 0))
    }, err => err);
  }
}
