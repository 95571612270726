import { http } from "@/core/ApiConfig";
import { Service } from "../shared";

export class EnderecoService extends Service {
    constructor(){
        super('endereco');   
    } 

    ListarEstados(){
        return http.get(`${this.GetNomeControle()}/estado?$orderby=nome`);
    }
    
    BuscarEstadoPorId(isEstadual: number = 0){
        return http.get(`${this.GetNomeControle()}/estado/${isEstadual}`);
    }
 
    ListarMunicipiosPorIdEstadual(idEstadual: number = 0){
        return http.get(`${this.GetNomeControle()}/estado/${idEstadual}/municipio?$orderby=nome`);
    }
    
    BuscarMunicipioPorId(id: number = 0){
        return http.get(`${this.GetNomeControle()}/estado/municipio/${id}`);
    }
      
    EncontrarEnderecoPorCep(cep: string){
        return http.get(`${this.GetNomeControle()}/cep/${cep}`);
    }
  }
      