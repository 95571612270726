import { http } from "@/core/ApiConfig";
import { Service } from "../shared";

export class AdiantamentoService extends Service{

    constructor(){
        super('adiantamentocarteira');
    }   

    DeletarItemContratoAdiantamento(id){ 
        return http.delete(`${this.GetNomeControle()}/deletarcontratoadiantamento/${id}`)
    } 

    ListarContratosPorAssinante(assinanteId,adiantamentoId, empresaId){
        return http.get(`${this.GetNomeControle()}/ListarContratosAdiantamento/${assinanteId}?adiantamentoId=${adiantamentoId}&empresaId=${empresaId}`)
    } 

    AprovarAdiantamento(adiantamentoId){
        return http.post(`${this.GetNomeControle()}/AprovarAdiantamento/${adiantamentoId}`)
    } 



    SalvarContratosAdiantamento(id, modelo){
        return http.post(`${this.GetNomeControle()}/SalvarContratosAdiantamento/${id}`, modelo)
    }
 
    ListarAdiantamento(page:number, pageSize:number, sortBy:any[], desc:any[], search:any, columns:any[], filter:any, include?:string){
        let parametros = this.GetParamentroPaginacaoOrdenacao(page,pageSize, sortBy,desc,search, columns);
        parametros = this.GetFiltroAtualizado(parametros, filter);
        return http.get(`${this.GetNomeControle()}/listaradiantamento${parametros}`,this.GetHeader(include))
    } 

    private GetFiltroAtualizado(parametros: string, filter: any){
        if (filter) {
        let paramTemp = '';

            if (filter.numero){
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `numero eq ${filter.numero}`;
            }
 
 
            if (paramTemp) {
                if(parametros){
                parametros += '&';
                }
                else {
                parametros += '?';
                }
                parametros += `$filter=${paramTemp}`;
            }
        }
        return parametros;  
    }

}