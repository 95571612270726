export default class Dominio {
    public id:number = 0;
    public nome:string ="";
    public principal:boolean = false
    public assinanteId:number = 0;
    
    constructor(model?: Dominio){
        if(!model)
        return;

        this.id = model.id;
        this.nome = model.nome;
        this.principal = model.principal;
        this.assinanteId = model.assinanteId;
    }
} 
 