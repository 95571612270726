import { http } from "@/core/ApiConfig";
import { Service } from "../shared";

export class IndiceService extends Service{

    constructor(){
        super('indicebase');
    }      
 
    public AtualizarUm(AssinanteId: number, executar:boolean){
        return http.post(`${this.GetNomeControle()}/atualizarindicecliente/${AssinanteId}/${executar}`);
    }

    public AtualizarTodos(executar:boolean){
        return http.post(`${this.GetNomeControle()}/AtualizarIndiceClientes/${executar}`);

    }
}