import { http } from "@/core/ApiConfig";
import { Service } from "../shared";

export class FinanceiroService extends Service{

    constructor(){
        super('financeiro');
    }  

    ListarCobranca(dataHoraInicial?:string, dataHoraFinal?:string, assinanteId?:any, parcelaId?:any, transacaoId?:any, erro?:any, isCobranca?:any, isBaixa?:any, isEmissaoBoleto?:any, isCancelamento?:any){
        return http.get(`https://api-logger.scae.adm.br/api/financeiro/cobranca?dataHoraInicial=${dataHoraInicial}&dataHoraFinal=${dataHoraFinal}&assinanteId=${assinanteId}&parcelaId=${parcelaId}&transacaoId=${transacaoId}&erro=${erro}&isCobranca=${isCobranca}&isBaixa=${isBaixa}&isEmissaoBoleto=${isEmissaoBoleto}&isCancelamento=${isCancelamento}`);
        // return http.get(`${this.GetNomeControle()}/cobranca?dataHoraInicial=${dataHoraInicial}&dataHoraFinal=${dataHoraFinal}&assinanteId=${assinanteId}&parcelaId=${parcelaId}&transacaoId=${transacaoId}&erro=${erro}&isCobranca=${isCobranca}&isBaixa=${isBaixa}&isEmissaoBoleto=${isEmissaoBoleto}&isCancelamento=${isCancelamento}`);
    }

    ListarTotais(dataHoraInicial?:any, dataHoraFinal?:any){
       return http.get(`https://api-logger.scae.adm.br/api/financeiro/cobranca/totais?dataHoraInicial=${dataHoraInicial}&dataHoraFinal=${dataHoraFinal}`);
    }

}