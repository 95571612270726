import Endereco from "../shared/Endereco";

export default class AssinanteResponsavel{
    public nome:string = "";
    public sobrenome:string ="";
    public codigoCategoriaComerciante:number = 0;
    public cpf:string = "";
    public telefone:string = "";
    public dataNascimento:string = "";
    public email:string = "";
    public endereco = new Endereco();  

    constructor(model?: AssinanteResponsavel){
        if(!model)
        return;

        this.nome = model.nome;
        this.sobrenome = model.sobrenome;
        this.codigoCategoriaComerciante = model.codigoCategoriaComerciante;
        this.cpf = model.cpf;
        this.telefone = model.telefone;
        this.dataNascimento = model.dataNascimento;
        this.email = model.email;
        this.endereco = model.endereco;
    }
} 
 