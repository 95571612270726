import { Service } from '@/core/services/shared';
import { Component, Prop, Watch, Vue, } from 'vue-property-decorator'

@Component
export default class ListPage extends Vue {
  protected service!: Service;
  protected item!: Shared.IEntity;
  protected lista: [] = [];
  protected totalLista: number = 0;
  protected loading: boolean = false;
  protected dialogCadastro: boolean = false;
  protected dialogBaixar: boolean = false;
  protected dialogEditar: boolean = false;
  protected dialogGerenciador: boolean = false;
  protected dialogAditar: boolean = false;
  protected dialogVisualizar: boolean = false;
  protected dialogDigital: boolean = false;
  protected dialogMapaInterativo:boolean = false;
  protected dialogReceberPedido:boolean = false;
  protected dialogRequisicao:boolean = false;

  protected timeout: any;
  public search: string = '';

  protected options: any = {
    itemsPerPage: 15
  };

  
  NovoCadastro(itemNovo: Shared.IEntity) {
    //this.item = itemNovo;
    this.dialogCadastro = true;
  }

  Listar() {
    this.loading = true;
    const { page, pageSize, sortBy, sortDesc, itemsPerPage,desc,} = this.options;
    this.service.Listar(page, pageSize, itemsPerPage,desc, sortBy, sortDesc)
      .then(
        res => {
          
          this.lista = res.data.items;
          this.totalLista = res.data.count;
          this.loading = false;
        },
        err => {
          this.$swal('Aviso', err.response.data, 'error');
          this.loading = false;
        }
      );
  }

  ListarTudo() {
    this.loading = true;
    this.service.ListarTudo().then(
      res => {
        // setTimeout(() => {
        //   this.loading = false;
        //   this.lista = res.data.items;
        //   this.totalLista = res.data.total;
        // }, 1000);
        this.loading = false;
        this.lista = res.data.items;
        this.totalLista = res.data.total;
      },
      err => {
        this.loading = false;
      }
    );
  }

  Editar(item: any) {
    this.service.ObterPorId(item.Id).then( res =>{
      this.item = res.data;
      this.dialogCadastro = true;
    });
  }

 

  BuscaRapida(termo: string) {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
    }, 1000);
  }

  Salvar(item?: Shared.IEntity) {
    let modelo = item != undefined ? item : this.item;
    this.service.Salvar(modelo!).then(
      res => { 
        this.$swal(
          "Aviso",
          "Operação realizada com sucesso!",
          res.status == 201 || res.status == 200 ? "success" : "warning"
        ); 
        this.dialogCadastro = false;
        this.$emit("fecharDialog");
      },
      err => {
        if (err.response.status == 400) {
          this.$swal("Aviso", err.response.data.title, "warning");
        } else {
          this.$swal("Aviso", err.response.data, "error");
        }
      }
    );
  }
}

