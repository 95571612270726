import { http } from "@/core/ApiConfig";
import { Service } from "../shared";

export class ContratoService extends Service{

    constructor(){ 
        super('contrato');
    }   

    ListarContratosAtualizados(page:number, pageSize:number, sortBy:any[], desc:any[], search:any, columns:any[], filter:any, include?:string){
        let parametros = this.GetParamentroPaginacaoOrdenacao(page,pageSize, sortBy,desc,search, columns);
        parametros = this.GetFiltroAtualizado(parametros, filter);
        return http.get(`${this.GetNomeControle()}/ListarContratos${parametros}`,this.GetHeader(include))
    }    

    private GetFiltroAtualizado(parametros: string, filter: any){
        if (filter) {
        let paramTemp = '';

            if (filter.numero){
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `numero eq ${filter.numero}`;
            }

            if (filter.sequencia){
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `sequencia eq ${filter.sequencia}`;
            }

            if (filter.tipoProdutoId){
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `tipoProdutoId eq ${filter.tipoProdutoId}`;
            }
            
            if (filter.tipoId){
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `tipoId eq ${filter.tipoId}`;
            }

            if (filter.data){
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `data eq ${filter.data}`;
            }

            if (filter.empreendimentoId){
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `empreendimentoId eq ${filter.empreendimentoId}`;
            }
            //or unidadesAdicionaisIds/any(o: o/grupo/empreendimentoId eq ${filter.empreendimentoId})

            if (filter.grupoId){
                if (paramTemp) { paramTemp += ' and '; } 
                paramTemp += `UnidadePrincipal/grupoId eq ${filter.grupoId}`;
            }

            if (filter.unidadeId) {
                if(paramTemp) { paramTemp += ' and '; }
                paramTemp += `UnidadePrincipalId eq ${filter.unidadeId} or unidadesAdicionais/any(o: o/unidadeId eq ${filter.unidadeId})`;
            }
            //or unidadesAdicionaisIds/any(o: o/id eq ${filter.unidadeId})
            
            if (filter.clienteIds){
                if(paramTemp) { paramTemp += ' and '; }
                paramTemp += `clientes/any(o: o/clienteId eq ${filter.clienteIds})`;
             }

             if (filter.email){
                if(paramTemp) { paramTemp += ' and '; }
                paramTemp += `clientes/any(o: o/cliente/email eq '${filter.email}')`;
             }
            
             if (filter.situacaoId){
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `situacaoId eq ${filter.situacaoId}`;
            }

            // if (filter.sequencia){
            //    if (paramTemp) { paramTemp += ' and '; }
            //    paramTemp += `sequencia eq ${filter.sequencia}`;
          //  }

            if (paramTemp) {
                if(parametros){
                parametros += '&';
                }
                else {
                parametros += '?';
                }
                parametros += `$filter=${paramTemp}`;
            }
        }
        return parametros;  
    }

}