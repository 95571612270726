
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { AdiantamentoService } from "@/core/services/cadastro";
import { AdiantamentoCarteira } from "@/core/models/cadastro";
 
@Component
export default class CadastroBanco extends Vue {
  @Prop() public item!: AdiantamentoCarteira;
  @Prop() public value!: string;

  service = new AdiantamentoService();
  lista = [];

  itemCarteira = new AdiantamentoCarteira();
  
  tab = null;
  valid = true;
  dialog = false;
  dialogContrato = false;
  errorAlert = false;
  loading = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  $refs!: {
    form: HTMLFormElement;
    formSplit: HTMLFormElement;
  }; 

  @Watch("itemCarteira")
  ItemCarteira() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation(); 
    } 
  }

  split:any = {};
  listaContaCorrentes = [];

  @Watch("split")
  Split() {
    if (this.$refs.formSplit) {
      this.$refs.formSplit.resetValidation(); 
    } 
  }

  headersSplit = [
    { text: '', value: 'actions' },
    { text: 'Valor', value: 'valor' },
    { text: 'Percentual/Fixo', value: 'isPercentual' },
  ]
   
  
  options: any = {
    itemsPerPage: 15 
  };  
  
  splitIndex = -1;
  listaSplit:any = [];

  AdicionarSplit(){
    if (this.$refs.formSplit.validate()) {

      if (this.splitIndex > -1) {
        Object.assign(this.listaSplit[this.splitIndex], this.split);
       } else {
        
        this.listaSplit.push(this.split);
       }
    }

    this.split = {};
    this.splitIndex = -1;
  }

  EditarItemSplit(item){
    this.splitIndex = this.listaSplit.indexOf(item);
    this.split = { ...item };  
  }

  ExcluirItemSplit(item){
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true, 
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm:true,
      preConfirm:() => {
        if(item.id > 0){
          item.excluido = true;
          const index = this.listaSplit!.indexOf(item);
          delete this.listaSplit![index]
          this.dialog = false;
          this.dialog = true;
          return true;
        }else{
          const index = this.listaSplit!.indexOf(item);
          this.listaSplit!.splice(index, 1);
          return this.$swal("Aviso", "Registro excluído com sucesso!", "success");
        }
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result) => {
      if(result.value){
      }
    })
  }

 
  @Watch("value") 
  Value() {
    this.dialog = this.value ? true : false; 

    if (this.dialog){
      this.itemCarteira = this.item;
     }
     
  }

  headersContratos = [
    { text: "Nº do Contrato", value: "numeroSequencia" },
    { text: "Saldo do Contrato", value: "saldoContrato" },
    { text: "Qtde de parcela", value: "totalParcelas" },
    { text: "Qtde parcela paga", value: "parcelasPagas" },
    { text: "Qtde parcelas a vencer", value: "parcelasVencer" },
    { text: "Valor da Parcela", value: "valorParcela" },
    { text: "Valor do Contrato", value: "valorTotalParcela" },
    { text: "Ações", value: "action" },
  ] 
 
  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  Salvar() {
    if (this.$refs.form.validate()) {
      this.service.Salvar(this.itemCarteira, this.itemCarteira.id).then(
        (res) => {
          this.$swal("Aviso","Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.AtualizarItem();
        },
        (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning" );
          } else {
            this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
          } 
        }
      )
    }
  }
  Aprovar(){
    this.service.AprovarAdiantamento(this.item.id).then(
        (res) => {
          this.$swal("Aviso","Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.AtualizarItem();
        },
        (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning" );
          } else {
            this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
          } 
        })
  }

  AdicionarContrato(item){
    const contratoExistente = this.itemCarteira.adiantamentoContratos.find((x:any) => x.contratoId === item.contratoId);
      if(contratoExistente){
        this.$swal("Aviso","Esse item já foi adicionado na lista!","warning");
        return;
      };
       
    this.itemCarteira.adiantamentoContratos.push(item);
    this.errorAlert = true;
  }

  isItemSelecionado(item) {
    return this.itemCarteira.adiantamentoContratos.some((x) => x.contratoId === item.contratoId);
  }

  RemoverContrato(item) {
    this.itemCarteira.adiantamentoContratos = this.itemCarteira.adiantamentoContratos.filter((x) => x.contratoId !== item.contratoId);
  }

  RemoverContratoAdiantamento(item){
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const res = await this.service.DeletarItemContratoAdiantamento(item.id);
          this.$swal("Aviso", "Registro excluído com sucesso!", "success");
          this.AtualizarItem();
        } catch (err:any) {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          }
        }
      },
    });
  }
  
  Close() {
    this.dialog = false;
  }

  @Watch("dialogContrato")
  ObterContratos() {

    if(this.dialogContrato == true)
    new AdiantamentoService().ListarContratosPorAssinante(this.itemCarteira.assinanteId,this.itemCarteira.id, this.itemCarteira.empresaSolicitante.empresaId).then(
      (res) => { 
        this.lista = res.data;
      }, 
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }
    ).finally(() => (this.loading = false));
  }

  AtualizarItem(){
    this.service.ObterPorId(this.itemCarteira.id, "SituacaoAdiantamentoCarteira, EmpresaSolicitante, TipoAdiantamentoCarteira, AdiantamentoContratos").then(
      (res) => {
        this.itemCarteira = res.data;
       }
    ); 
  }
 
 get totalSelecionados() {
    // Somar o valorParcela de todos os contratos selecionados
    return this.itemCarteira.adiantamentoContratos.reduce((total, obj) => {
       
      const contrato:any = this.lista.find((item:any) => item.contratoId === obj.contratoId);
     return contrato ? total + Number(contrato.valorParcela) : total;
    }, 0);
  }
}
