import Vue from 'vue'
import VueRouter, {RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '*',
    redirect: '/login'
  },

  {
    path: '/',  
    alias: '/home',   
    name: 'home', 
    component: Home,
    beforeEnter: (to: any, from: any, next: any) => {
   
      if (localStorage.getItem('sessionApp') != null) {
         let exp: any = JSON.parse(atob(localStorage.getItem('sessionApp')!.split('.')[1])).exp;
 
        let expDate = new Date(exp * 1000);
        let today = new Date();
        if (today > expDate) {
          localStorage.clear();
          next('/login');
        } else { 
          next();
        }
      } else {
        next('/login');  
      }
    }
  },  
  
  { path: '/', name: 'Home', component: () => import("../views/Home.vue") },
  { path: '/login', name: 'Login', component: () => import("../views/Login.vue") },

   
  /*Cadastro*/
  {path: '/base/dominio',name: 'dominio',component: () => import('../views/cadastro/dominio/ListaDominio.vue')},
  {path: '/base/assinante',name: 'assinante',component: () => import('../views/cadastro/assinante/ListaAssinante.vue')},
  {path: '/base/adiantamento',name: 'adiantamento',component: () => import('../views/cadastro/adiantamento/ListaAdiantamento.vue')},

  /*Work*/
  {path: '/base/log',name: 'log',component: () => import('../views/work/log/ListaLog.vue')},
  {path: '/base/logAssinante', name: 'logAssinante',component: () => import('../views/work/logassinante/ListaLogAssinante.vue')},

  /*Ferramentas*/
  {path: '/base/indice', name:'indice',component: () => import('@/views/global/indice.vue')},
] 

const router = new VueRouter({ 
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
