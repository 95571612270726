
import { PageBase } from "@/core/models/shared";
import { Component, Prop, Watch, Vue } from "vue-property-decorator";

@Component
export default class Home extends PageBase {
 loading:boolean = false;

 
}
