import { Endereco } from "../shared";
import AssinanteResponsavel from "./AssinanteResponsavel";

export default class AssinanteDominio{
    public id:number = 0;
    public gerarZoop:boolean = false;
    public subDominio:string = "";
    public responsavel = new AssinanteResponsavel();
    public pessoaJuridica:boolean = false;
    public cpfCnpj:string = "";
    public nome:string ="";
    public nomeFantasia:string = "";
    public inscricaoEstadual:string = "";
    public inscricaoMunicipal:string = "";
    public endereco = new Endereco();
    public telefone:string = "";
    public telefone2:string = "";
    public email:string = "";
    public ativo:boolean = true;
    public contatos = [];
    public dominios:any = [];
  
    constructor(model?: AssinanteDominio){
        if(!model)
        return;

        this.id = model.id;
        this.gerarZoop = model.gerarZoop;
        this.subDominio = model.subDominio;
        this.responsavel = model.responsavel;
        this.pessoaJuridica = model.pessoaJuridica;
        this.cpfCnpj = model.cpfCnpj;
        this.nome = model.nome;
        this.nomeFantasia = model.nomeFantasia;
        this.inscricaoEstadual = model.inscricaoEstadual;
        this.inscricaoMunicipal = model.inscricaoMunicipal;
        this.endereco = model.endereco;
        this.telefone = model.telefone;
        this.telefone2 = model.telefone2;
        this.email = model.email;
        this.ativo = model.ativo;
        this.contatos = model.contatos;
        this.dominios = model.dominios
    }
} 
 