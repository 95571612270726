import { http } from '@/core/ApiConfig';
import { Service } from '../shared';
 
 
export class ReceitaService extends Service{
        constructor(){ 
            super('receita');  
        }   

        Executar(modelo: any){
            return http.post(`${this.GetNomeControle()}/Reajustar`, modelo);
        }
    }