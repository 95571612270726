
import { Vue, Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ListPage from '@/components/shared/ListPage';
import { AdiantamentoCarteira, Assinante } from '@/core/models/cadastro';
import { AdiantamentoService } from '@/core/services/cadastro';
 
@Component
export default class ListaAdiantamento extends mixins(Vue,ListPage) {
  item = new AdiantamentoCarteira();
  service = new AdiantamentoService();
 
  loading: boolean = false;
  dialogCadastro: boolean = false;
  totalLista: number = 0;
  
  titulo: string = 'Adiantamento da Carteira de Recebíveis';
  subTitulo: string = 'Lista das solicitações';

  options: any = {
    itemsPerPage: 15
  }; 
      
  headers: any[] = [
    { text: '', value: 'actions', sortable: false },
    { text: 'Empresa Solicitante', value: 'empresaSolicitante.nome' },
    { text: 'Data do Cadastro', value: 'dataCriacao' },
    { text: 'Situação', value: 'situacaoAdiantamentoCarteira.nome' },
  ];  
   
 @Watch("options", { deep: true }) 
  Atualizar() { 
    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
    this.loading = true;

    this.service.ListarAdiantamento(page,itemsPerPage, sortBy, sortDesc, search, columns, '', 'SituacaoAdiantamentoCarteira').then(
      res => {
        this.lista = res.data;
        this.totalLista = res.data.count; 
      },
      (err) => { 
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }).finally(() => (this.loading = false));
  }
 
  Novo(){
    this.item = new AdiantamentoCarteira();
    this.dialogCadastro = true;
  }

  Editar(item: AdiantamentoCarteira) {
    this.service.ObterPorId(item.id, 'SituacaoAdiantamentoCarteira, EmpresaSolicitante, TipoAdiantamentoCarteira, AdiantamentoContratos').then(
      res => {
        this.item = new AdiantamentoCarteira(res.data);
        //this.item.contratosAdiantameto =[];
        this.dialogCadastro = true;
      } 
    );
  }
  
  Excluir(item) {
   this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const res = await this.service.Excluir(item.Id);
          this.$swal("Aviso", "Registro excluído com sucesso!", "success");
          this.Atualizar();
        } catch (err:any) {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          }
        }
      },
    });
  }

  situacaoColor(item){ 
    if(item.situacaoAdiantamentoCarteiraId == 1)
      return "blue lighten-3";

      else if(item.situacaoAdiantamentoCarteiraId == 2) 
      return "orange lighten-1";

      else if(item.situacaoAdiantamentoCarteiraId == 3)
      return "deep-purple lighten-1";

      else if(item.situacaoAdiantamentoCarteiraId == 4)
      return "red lighten-1";
  
      else if(item.situacaoAdiantamentoCarteiraId == 5)
      return "grey lighten-1";

      else if(item.situacaoAdiantamentoCarteiraId == 6) 
      return "teal lighten-1";

      else if(item.situacaoAdiantamentoCarteiraId == 7)
      return "yellow lighten-1";
  }

  mounted(){

  }
}
