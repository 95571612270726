
import { Vue, Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ListPage from '@/components/shared/ListPage';
import Log from '@/core/models/base/Log';
import { FinanceiroService } from '@/core/services/work';
import { AssinanteDominioService, AssinanteService } from '@/core/services/cadastro';

@Component
export default class ListaLog extends mixins(Vue,ListPage) {
  item = new Log();
  service = new FinanceiroService(); 

  loading: boolean = false;
  dialogCadastro: boolean = false;
  sheet:boolean = false;
  
  selectedOptions:any = [];
  listaAssinantes = [];
  
  titulo: string = 'Log';
  subTitulo: string = 'Lista de Log do sistema Scae';
  totalLista: number = 0;
  
  options: any = {
    itemsPerPage: 100
  };

  statusItens = [
    {erro:true, nome:"Com erro"},
    {erro:false, nome:"Sem erro"},
  ]
  
  filterOptions = [
    { value: 'isCobranca', label: 'Cobrança Enviada' },
    { value: 'isBaixa', label: 'Baixa Automática' },
    { value: 'isEmissaoBoleto', label: 'Boleto Gerado' },
    { value: 'isCancelamento', label: 'Parcela Cancelada' },
  ];

 
  inicioDoDia = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0);
  finalDoDia = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59, 999);

  formatarData = (data) => {
    const pad = (num) => (num < 10 ? '0' : '') + num;
    return `${data.getFullYear()}-${pad(data.getMonth() + 1)}-${pad(data.getDate())}T${pad(data.getHours())}:${pad(data.getMinutes())}`;
  };

  filter = {
    dataHoraInicial: this.formatarData(this.inicioDoDia),
    dataHoraFinal: this.formatarData(this.finalDoDia),
    assinanteId:0,
    parcelaId:0,
    transacaoId: 0, 
    erro:false,
    isCobranca:false,
    isBaixa:false,
    isEmissaoBoleto:false,
    isCancelamento:false
  }
     
  headers: any[] = [ 
    { text: 'Cód. Assinante', value: 'AssinanteId', sortable: false },
    { text: 'Descrição', value: 'Descricao', sortable: false },
    { text: 'Data e Hora', value: 'DataHora', sortable: false },
    { text: 'Cód. Parcela', value: 'ParcelaId', sortable: false },
    { text: 'Cód. Transação', value: 'TransacaoId', sortable: false },
    { text: 'Origem', value: 'Origem', sortable: false }
  ];
   
  Consultar(){
    let erro = this.filter.erro == null ? '' : this.filter.erro.toString();
    let isCobranca = false, isBaixa = false, isEmissaoBoleto = false, isCancelamento = false;

    if(this.selectedOptions){
      isCobranca = this.selectedOptions.includes('isCobranca');
      isBaixa = this.selectedOptions.includes('isBaixa');
      isEmissaoBoleto = this.selectedOptions.includes('isEmissaoBoleto');
      isCancelamento = this.selectedOptions.includes('isCancelamento');
    }

    let assinanteId = this.filter.assinanteId ? this.filter.assinanteId : 0;
    this.loading = true;
 
    this.service.ListarCobranca(this.filter.dataHoraInicial, this.filter.dataHoraFinal, assinanteId, this.filter.parcelaId, this.filter.transacaoId, erro, isCobranca,isBaixa, isEmissaoBoleto, isCancelamento).then(
      res => {
        this.lista = res.data;
        this.totalLista = res.data.count;
      }, 
      err => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } 
        else {
          this.$swal("Aviso", err.response.data, "error");
        }
      } 
    ).finally(() => (this.loading = false));
  } 
  
  @Watch("options", { deep: true })
  AtualizarAutomatico(){
    setInterval(() => {
      this.Consultar();
    },300000)
  }

  mounted(){
    this.Consultar();
    
    const assinanteService = new AssinanteDominioService();
      assinanteService.ListarTudo().then(
        res => {
          this.listaAssinantes = res.data.items;
        }
      )
  }
}
