 export default class Log implements Shared.IEntity{
    public id:number = 0;
    public Id:string = "0";
    public AssinanteId:number = 0;
    public Nome:string ="";
    public ParcelaId:number = 0;
    public TransacaoId:number = 0;
    public Descricao:string = "";
    public Erro:string ="";

    public IsCobranca:boolean = false;
    public IsBaixa:boolean = false;
    public IsEmissaoBoleto:boolean = false; 
    public IsCancelamento:boolean = false;
    
    constructor(model?: Log){
        if(!model)
        return;

        this.Id = model.Id;
        this.AssinanteId = model.AssinanteId;
        this.Nome = model.Nome;
        this.ParcelaId = model.ParcelaId;
        this.TransacaoId = model.TransacaoId;
        this.Descricao = model.Descricao;
        this.Erro = model.Erro;

        this.IsCobranca = model.IsCobranca;
        this.IsBaixa = model.IsBaixa;
        this.IsEmissaoBoleto = model.IsEmissaoBoleto;
        this.IsCancelamento = model.IsCancelamento;
    }
} 
 