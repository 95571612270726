import Vue from 'vue'
import MasterPage   from "./shared/MasterPage.vue";
import CadastroAssinante from "./cadastro/assinante/Cadastro.vue";
import CadastroAssinanteDominio from "./cadastro/dominio/Cadastro.vue";
import VisualizarLogAssinante from "./work/logassinante/Visualizar.vue";
import CadastroAdiantamento from "./cadastro/adiantamento/Cadastro.vue";
import CadastroIndice from '@/components/global/indice/Cadastro.vue'; 

Vue.component("cadastro-assinantedominio", CadastroAssinanteDominio);
Vue.component("cadastro-assinante", CadastroAssinante);
Vue.component("visualizar-logassinante", VisualizarLogAssinante);
Vue.component("cadastro-adiantamento", CadastroAdiantamento);
Vue.component('cadastro-indice', CadastroIndice);
Vue.component("master-page", MasterPage);
 
