
import { PageBase } from "@/core/models/shared";
import { UsuarioService } from "@/core/services/geral";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class MasterPage extends PageBase {
    @Prop() private icone!: string;
    @Prop() private titulo!: string;
    @Prop() private subTitulo!: string;

    drawer:boolean = false;
    active:boolean = false;
    dialogAlterarSenha: boolean = false;
    dialogMeusDados:boolean = false;
    dialogListaContratos:boolean = false;

    mounted() {
        this.$vuetify.theme.dark = this.app.temaEscuro;
    };

    FullScreen(){
        let elem = document.documentElement;
        if (!document.fullscreenElement) {
            elem.requestFullscreen().catch(err => {
                alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
            });
        } 
        else {
            document.exitFullscreen();
        }
    } 

    AbrirWhatsApp(){
      /*  new AssinanteService().WhatsApp(this.app.assinanteId).then(
            res => {
                let numero = res.data;
                numero = numero.replaceAll("-", "");
                numero = numero.replaceAll(" ", "");
                numero = numero.trim();
                window.open(`https://api.whatsapp.com/send?phone=55${numero}`);
            },
            err =>{
                this.$swal('Aviso', err.message, 'error')
            }
        )*/
    }

    MudarTema(){
        const usuarioService = new UsuarioService();
        usuarioService.MudarTema(this.app.usuarioId).then(
            res => super.MudarTema(res.data),
            err => this.$swal('Aviso', err.message, 'error')
        );
    };   
    
    AlterarSenha(){
        this.dialogAlterarSenha = true;
    };

    Logout() {
        localStorage.clear();
        this.$vuetify.theme.dark = false;
        this.$router.push({name: 'Login'});
    };
}
