import { Service } from "../shared";
import {http} from '@/core/ApiConfig';


export class TipoIndiceService extends Service{

    constructor(){
        super('tipoindice');
    }      


}