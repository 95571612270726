
import { Vue, Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ListPage from '@/components/shared/ListPage';
import Log from '@/core/models/base/Log';
import { FinanceiroService } from '@/core/services/work';
 
@Component
export default class ListaLog extends mixins(Vue,ListPage) {
  item = new Log();
  service = new FinanceiroService(); 

  loading: boolean = false; 
  dialogCadastro: boolean = false;
  sheet:boolean = false;
  
  titulo: string = 'Log por Assinante';
  subTitulo: string = 'Lista de Log do sistema Scae';
  totalLista: number = 0;
  
  options: any = {
    itemsPerPage: 100
  };

  headers: any[] = [
    { text: '', value: 'actions', sortable: false },
    { text: 'Assinante', value: 'Nome', sortable: false },
    { text: 'Boletos Sucesso', value: 'BoletosSucesso', sortable: false },
    { text: 'Boletos Erro', value: 'BoletosErro', sortable: false },
    { text: 'Cobrança Sucesso', value: 'CobrancasSucesso', sortable: false },
    { text: 'Cobrança Erro', value: 'CobrancasErro', sortable: false },
    { text: 'Baixas Sucesso', value: 'BaixasSucesso', sortable: false },
    { text: 'Baixas Erro', value: 'BaixasErro', sortable: false },
    { text: 'Agrupadas Sucesso', value: 'AgrupadasVencidasCanceladasSucesso', sortable: false },
    { text: 'Agrupadas Erro', value: 'AgrupadasVencidasCanceladasErro', sortable: false }
  ];

  inicioDoDia = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0);
  finalDoDia = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59, 999);

  formatarData = (data) => {
    const pad = (num) => (num < 10 ? '0' : '') + num;
    return `${data.getFullYear()}-${pad(data.getMonth() + 1)}-${pad(data.getDate())}T${pad(data.getHours())}:${pad(data.getMinutes())}`;
  };

  filter = {
    dataHoraInicial: this.formatarData(this.inicioDoDia),
    dataHoraFinal: this.formatarData(this.finalDoDia),
    nome:'',
    AssinanteId: 0,
    erro:true,
  };

  @Watch("options", { deep: true })
  Atualizar(){
    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
    this.loading = true;

    this.service.ListarTotais(this.filter.dataHoraInicial, this.filter.dataHoraFinal).then(
      res => {
        this.lista = res.data;
        this.totalLista = res.data.count;
      }, 
      err => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } 
        else {
          this.$swal("Aviso", err.response.data, "error");
        }
      } 
    ).finally(() => (this.loading = false));
  }

  VisualizarLog(item){
    this.filter.nome = item.Nome
    this.filter.AssinanteId = item.Id;
    this.dialogCadastro = true;
  }
  
  mounted(){   

  }
}

