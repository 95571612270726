
    export default class Assinante  implements Shared.IEntity  {
    public id:number = 0;
    public Id:string = "";
    public Nome:string ="";
    public AssinanteId:number = 0;
  
    constructor(model?: Assinante){
        if(!model)
        return;

        this.Id = model.Id;
        this.Nome = model.Nome;
        this.AssinanteId = model.AssinanteId;
    }
} 
 