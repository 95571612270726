export default class AdiantamentoCarteira  implements Shared.IEntity  {
    public id: number = 0;
    public empresaSolicitante?:any;
    public empresaSolicitanteId:number = 0;
    public objetivo:string  = "";
    public valor:number = 0;
    public primeiroMesParcela:string = "";
    public quantidadeParcelas:number = 0;
    public tipoAdiantamentoCarteira?:any;
    public tipoAdiantamentoCarteiraId:number = 0;
    public situacaoAdiantamentoCarteiraId:number = 0;
    public assinanteId?:Number;
    public adiantamentoContratos:any = [];
    public valorParcela: number = 0; 
    public listaSplit:any = [];
       
    constructor(model?: AdiantamentoCarteira){
        if(!model) 
        return;
    
        this.id = model.id; 
        this.empresaSolicitante = model.empresaSolicitante;
        this.empresaSolicitanteId = model.empresaSolicitanteId;
        this.objetivo = model.objetivo;
        this.valor = model.valor;
        this.primeiroMesParcela = model.primeiroMesParcela;
        this.quantidadeParcelas = model.quantidadeParcelas;
        this.tipoAdiantamentoCarteira = model.tipoAdiantamentoCarteira;
        this.tipoAdiantamentoCarteiraId = model.tipoAdiantamentoCarteiraId;
        this.situacaoAdiantamentoCarteiraId = model.situacaoAdiantamentoCarteiraId;
        this.assinanteId = model.assinanteId;
        this.adiantamentoContratos = model.adiantamentoContratos;
        this.valorParcela = model.valorParcela;
        this.listaSplit = model.listaSplit;
    }     
}