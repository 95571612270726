
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import * as jsonpatch from 'fast-json-patch';
import { AssinanteService } from "@/core/services/cadastro";
import { Assinante } from "@/core/models/cadastro";
 
@Component
export default class CadastroAssinante extends Vue {
  @Prop() public item!: Assinante;
  @Prop() public value!: string;

  service = new AssinanteService();
  valid = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  observer! : jsonpatch.Observer<Assinante>; 

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false; 
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  $refs!: {
    form: HTMLFormElement;
  };

  @Watch("item")
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }
 
  Salvar() {
    if (this.$refs.form.validate()) {   
      this.service.Salvar(this.item, this.item.Id).then(
        (res) => {
          this.$swal("Aviso","Operação realizada com sucesso!", "success");
          this.$emit("salvou");
          this.Close();
        },
        (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", "N", "warning" );
          }
       });
    }
  }

  Close() {
    this.dialog = false;
  }
}
