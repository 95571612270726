
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
 import { AssinanteService } from "@/core/services/cadastro";
import { FinanceiroService } from "@/core/services/work";

@Component
export default class LogAssinante extends Vue {
  @Prop() public item!: ModeloFiltro;
  @Prop() public value!: string;

  service = new FinanceiroService();

  valid = true;
  dialog = false;
  sheet:boolean = false;
  loading:boolean = false;
  
  lista = [];
  listaAssinantes = [];
  selectedOptions:any = [];
  totalLista: number = 0;

  options: any = {
    itemsPerPage: 100
  };

  headers: any[] = [
    { text: 'Descrição', value: 'Descricao', sortable: false },
    { text: 'Data e Hora', value: 'DataHora', sortable: false },
    { text: 'Cód. Parcela', value: 'ParcelaId', sortable: false },
    { text: 'Cód. Transação', value: 'TransacaoId', sortable: false },
    { text: 'Origem', value: 'Origem', sortable: false }
  ];

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false; 
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }else{
      this.Consultar();
    }
  }

  $refs!: {
    form: HTMLFormElement;
  };

  Close() {
    this.dialog = false;
  }

  Consultar(){
    let erro = this.item.erro ? this.item.erro.toString() : '';
    let isCobranca = this.selectedOptions.includes('isCobranca');
    let isBaixa = this.selectedOptions.includes('isBaixa');
    let isEmissaoBoleto = this.selectedOptions.includes('isEmissaoBoleto');
    let isCancelamento = this.selectedOptions.includes('isCancelamento');
    let assinanteId = this.item.AssinanteId ? this.item.AssinanteId : 0;
    let parcelaId = this.item.parcelaId ? this.item.parcelaId : 0;
    let transacaoId = this.item.transacaoId ? this.item.transacaoId : 0;
 
    this.loading = true;

    this.service.ListarCobranca(this.item.dataHoraInicial, this.item.dataHoraFinal, assinanteId, parcelaId, transacaoId, erro, isCobranca,isBaixa, isEmissaoBoleto, isCancelamento).then(
      res => {
        this.lista = res.data;
        this.totalLista = res.data.count;
      }, 
      err => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } 
        else {
          this.$swal("Aviso", err.response.data, "error");
        }
      }).finally(() => (this.loading = false));
  }
  
  filterOptions = [
    { value: 'isCobranca', label: 'Cobrança Enviada' },
    { value: 'isBaixa', label: 'Baixa Automática' },
    { value: 'isEmissaoBoleto', label: 'Boleto Gerado' },
    { value: 'isCancelamento', label: 'Parcela Cancelada' },
  ];

  mounted(){
    const assinanteService = new AssinanteService();
      assinanteService.ListarTudo().then(
        res => {
          this.listaAssinantes = res.data;
        }
      )
  }
} 
 
class ModeloFiltro {
   /*formatarData = (data) => {
    const pad = (num) => (num < 10 ? '0' : '') + num;
    return `${data.getFullYear()}-${pad(data.getMonth() + 1)}-${pad(data.getDate())}T${pad(data.getHours())}:${pad(data.getMinutes())}`;
  };*/

  dataHoraInicial = '';
  dataHoraFinal = ''; 
  AssinanteId = 0;
  parcelaId = 0;
  transacaoId = 0;
  erro = false;
  isCobranca = false;
  isBaixa = false;
  isEmissaoBoleto = false;
  isCancelamento = false    
}
