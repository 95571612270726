
import { Vue, Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ListPage from '@/components/shared/ListPage';
import { AssinanteDominioService, IndiceService } from '@/core/services/cadastro';
import { Indice } from '@/core/models/cadastro';
import { AssinanteService } from '@/core/services/cadastro';
 
@Component
export default class ListaIndice extends mixins(Vue,ListPage) {
  item = new Indice();
  service = new IndiceService(); 
  reajustar = new Reajustar();
  envioOpcoes: string = 'um';

  loading: boolean = false;
  dialogCadastro: boolean = false;
  totalLista: number = 0;
  dialog: boolean =  false;
  listaAssinantes = [];
  assinanteId: number = 0;

  titulo: string = 'Índice';
  subTitulo: string = 'Lista dos Índices para o uso no sistema';
  
  options: any = {
    itemsPerPage: 15
  };
  
  headers: any[] = [ 
    { text: '', value: 'actions', sortable: false },
    { text: 'Mês', value: 'mes', type:'number'},
    { text: 'Ano', value: 'ano', type:'number'},
    { text: 'Tipo do indice', value: 'tipoIndice.nome'},
    { text: 'Mensal', value: 'mensal'}, 
    { text: 'Acumulado', value: 'acumulado', type:'number' }, 
    { text: 'Total', value: 'total', type:'null', sortable: false }, 
    { text: 'Executado', value: 'executado', type:'boolean'} 
  ]; 

  meses = [
    { id: 1, nome: "Janeiro" },
    { id: 2, nome: "Fevereiro" },
    { id: 3, nome: "Março" },
    { id: 4, nome: "Abril" },
    { id: 5, nome: "Maio" },
    { id: 6, nome: "Junho" },
    { id: 7, nome: "Julho" },
    { id: 8, nome: "Agosto" },
    { id: 9, nome: "Setembro" },
    { id: 10, nome: "Outubro" },
    { id: 11, nome: "Novembro" },
    { id: 12, nome: "Dezembro" },
  ];

  
   breadCrumbLinks: any[] = [
    { text: 'Home', href: '/financeiro/painel/kpi',},
    { text: 'Lista de Índices', disabled: true, href: '/financeiro/painel/despesas'}
  ]

  mounted(){
    const assinanteService = new AssinanteDominioService();
      assinanteService.ListarTudo().then(
        res => {
          this.listaAssinantes = res.data.items;
        }
      )
  }

 
  @Watch("options", { deep: true })  
  Atualizar() {  
    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
    this.loading = true;

    this.service.Listar(page,itemsPerPage,sortBy,sortDesc,search, columns, undefined, 'TipoIndice').then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }).finally(() => (this.loading = false));
  } 

  AbrirIndice(item){
    if(item){
      this.service.ObterPorId(item.id).then(res =>{
        this.item = new Indice(res.data);
        this.dialogCadastro = true;
      }
    )
    }else{
      this.item = new Indice();
      this.dialogCadastro = true;
    }
  }
  
  Excluir(item) {
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: "question",
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,

      preConfirm: () => {
        return this.service.Excluir(item.id)
          .then(res => {
            if (res.status == 200) {
              return res;
            }
          },
            err => this.$swal('Aviso', err.response.data, 'error')
          )
      },
      allowOutsideClick: false/*() => !this.$swal.isLoading()*/
    })
      .then((result:any) => {
        if (result.value) {
          this.$swal('Aviso', result.value.data, result.value.status == 200 ? 'success' : 'warning');
          this.Atualizar();
        }
      })
  }
  
  Executar(AssinanteId, executar){ 

    this.$swal({
    icon: "question",
    title:"Atenção",
    text: "Deseja atualizar o Índice ? Essa ação será irreversível. Todos os contratos serão atualizados. Concorda?",
    input: "text",
    inputPlaceholder: 'SIM OU NÃO',
    showCancelButton: true,
    confirmButtonText: 'Salvar',
    cancelButtonText: "Cancelar",
    preConfirm: (text) => {
      if(text == 'sim' || text == 'Sim' ||  text == 'SIM'){
        if(this.envioOpcoes == "um"){
          return this.service.AtualizarUm(AssinanteId, executar).then(
            (res) => {
              if (res.status == 200) {
                this.Atualizar(); 
                return res;
              }
            },
            (err) => {
              if (!err.response) {
                this.$swal("Aviso", "Não foi possível acessar a API", "error");
              } else if (err.response.status == 403) {
                this.$swal("Aviso", err.response.data.message, "warning" );
              } else {
                this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
              }
            }); 
        }
        if(this.envioOpcoes == "todos"){
          return   this.service.AtualizarTodos(executar).then(
            (res) => {
              if (res.status == 200) {
                this.Atualizar(); 
                return res;
              }
            },
            (err) => {
              if (!err.response) {
                this.$swal("Aviso", "Não foi possível acessar a API", "error");
              } else if (err.response.status == 403) {
                this.$swal("Aviso", err.response.data.message, "warning" );
              } else {
                this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
              }
            }); 
          }
      }},
  
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result: any) => {
      if (result.value.status == 200) {
          this.$swal("Aviso", "Índice atualizado.", "success");
     }else{
       this.$swal("Aviso", "Índice NÃO atualizado, para atualizar escreva SIM. na caixa de texto e aperte Salvar.", "warning");
     }
    })
  }
}


class Reajustar{
  public indiceId:number = 0;
}

